import React, { useEffect, useState } from "react";
import Home3Header from "./header";
import Footer from "../../footer";
import { Link } from "react-router-dom";
import ImageWithBasePath from "../../../../core/img/imagewithbasebath";
import AOS from "aos";
import "aos/dist/aos.css";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { Dropdown } from "primereact/dropdown";
import {Helmet} from "react-helmet";
import config from "../../../../../config";

const Home = (props) => {
  const [selectValueSpecialization, setSelectValueSpecialization] = useState([]);
  const [selectValueProvince, setSelectValueProvince] = useState([]);
  const [selectedValueSpecialization, setSelectedValueSpecialization] = useState(null);
  const [selectedValueProvince, setSelectedValueProvince] = useState(null);
  const [filteredPractitioners, setFilteredPractitioners] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [ShowDoctor, setValueShowDoctor] = useState([]);

  const clinicSlider = {
    loop: true,
    margin: 15,
    dots: false,
    nav: true,
    navContainer: ".slide-nav-1",
    navText: [
      '<i class="fas fa-chevron-left custom-arrow"></i>',
      '<i class="fas fa-chevron-right custom-arrow"></i>',
    ],
    responsive: {
      0: { items: 2 },
      500: { items: 2 },
      768: { items: 3 },
      1000: { items: 4 },
      1300: { items: 6 },
    },
  };

  document.addEventListener("DOMContentLoaded", function() {
    const owl = $('.clinics').owlCarousel(clinicSlider);

    $('.clinic-link').on('click', function(e) {
      e.preventDefault();
      const href = $(this).attr('href');
      window.location.href = href;
    });
  });

  const clinicData = [
    {
      link: "/lista-dottori/Dermatologo/und/und/"+config.specializationSlider[3],
      image: "assets/img/clinic-5.jpg",
      categoryImage: "assets/img/category/category-06.svg",
      specialization: "Dermatologo",
    },
    {
      link: "/lista-dottori/Medico-estetico/und/und/"+config.specializationSlider[5],
      image: "assets/img/clinic-2.jpg",
      categoryImage: "assets/img/category/category-02.svg",
      specialization: "Medico estetico",
    },
    {
      link: "/lista-dottori/Medico-di-medicina-generale/und/und/"+config.specializationSlider[2],
      image: "assets/img/clinic-4.jpg",
      categoryImage: "assets/img/category/category-05.svg",
      specialization: "Medicina generale",
    },
    {
      link: "/lista-dottori/Nutrizionista/und/und/"+config.specializationSlider[1],
      image: "assets/img/clinic-5.jpg",
      categoryImage: "assets/img/category/category-01.svg",
      specialization: "Nutrizionista",
    },
    {
      link: "/lista-dottori/Osteopata/und/und/"+config.specializationSlider[0],
      image: "assets/img/clinic-2.jpg",
      categoryImage: "assets/img/category/category-02.svg",
      specialization: "Osteopata",
    },
    {
      link: "/lista-dottori/Cardiologo/und/und/"+config.specializationSlider[4],
      image: "assets/img/clinic-4.jpg",
      categoryImage: "assets/img/category/category-03.svg",
      specialization: "Cardiologo",
    },
  ];

  const ourDoctorSlider = {
    loop: true,
    margin: 15,
    dots: false,
    nav: true,
    navContainer: ".slide-nav-2",
    navText: [
      '<i class="fas fa-chevron-left custom-arrow"></i>',
      '<i class="fas fa-chevron-right custom-arrow"></i>',
    ],
    responsive: {
      0: { items: 2 },
      500: { items: 2 },
      768: { items: 2 },
      1000: { items: 3 },
      1200: { items: 4 },
    },
  };

  const combinedOptions = [
    ...(Array.isArray(filteredPractitioners) ? filteredPractitioners.map(p => ({
      ...p,
      name: `👨‍⚕️ ${p.name + ', '+ p.specializationDescription}`
    })) : []),
    ...selectValueSpecialization
  ];
  
  const getSpecialization = () => {
    const url = `${config.apiUrl}/SMB/SpecializationList`;

    return fetch(url)
        .then((response) => {
          const contentType = response.headers.get("content-type");
          if (contentType && contentType.includes("application/json")) {
            return response.json();
          } else {
            throw new Error("Response is not JSON");
          }
        })
        .then((responseData) => {
          const data = responseData.data;
          const formattedData = [];
          for (var i = 0; i < data.length; i++) {
            const item = data[i];
            if (item && item.description && item.description !== 'Amministrazione') {
              formattedData.push({ id: item.id, name: item.description });
            }
          }
          return formattedData;
        })
        .catch((error) => {
          console.error('Error fetching data:', error);
          throw error;
        });
  };

  const getProvince = (italyOnly) => {
    const url = `${config.apiUrl}/SMB/ProvinceList?italyOnly=${italyOnly}`;

    return fetch(url)
        .then((response) => {
          const contentType = response.headers.get("content-type");
          if (contentType && contentType.includes("application/json")) {
            return response.json();
          } else {
            throw new Error("Response is not JSON");
          }
        })
        .then((responseData) => {
          const data = responseData.data;
          const formattedData = [];
          for (var i = 0; i < data.length; i++) {
            const item = data[i];
            if (item && item.id && item.description && item.id !== "EW3YE" && item.description !== " Provincia o comune non presente") {
              formattedData.push({ id: item.id, name: item.description });
            }
          }
          return formattedData;
        })
        .catch((error) => {
          console.error('Error fetching data:', error);
          throw error;
        });
  };

  const getPractitionerListByRequest = async (request) => {
    const url = `${config.apiUrl}/SMB/PractitionersListByRequest?request=${request}`;

    try {
      const response = await fetch(url);

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const contentType = response.headers.get("content-type");
      if (!contentType || !contentType.includes("application/json")) {
        throw new Error("Response is not JSON");
      }

      const responseData = await response.json();

      if (Array.isArray(responseData.data.content) && responseData.data.content.length) {
        const practitioners = responseData.data.content.map(item => {
          return {
            id: item.practitioner.id,
            name: `${item.practitioner.firstName} ${item.practitioner.lastName}`,
            specializationId: item.practitionerSpecializationId,
            specializationDescription: item.specialization.description,
            type: 'practitioner'
          };
        });
        return practitioners;
      } else {
        console.error("No valid practitioners found:", responseData);
        return [];
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      throw error;
    }
  };
  
  useEffect(() => {

    const handleScroll = () => {
      AOS.refresh();
    };

    window.addEventListener("scroll", handleScroll);
    
    ShowPractitioner()
        .then((formattedData) => {
          let uniqueDoctors = [];
          let seenIds = new Set();
          let count = 0;
          const maxLimit = 10;

          uniqueDoctors = formattedData.filter(doc => {
            const id = doc.practitioner?.id;
            if (id && !seenIds.has(id)) {
              seenIds.add(id);
              if (count < maxLimit) {
                count++;
                return true;
              }
            }
            return false;
          });

          setValueShowDoctor(uniqueDoctors);
        })
        .catch((error) => {
          console.error('Errore durante il recupero dei dati:', error);
        });

    AOS.init({ duration: 1000 });

    getSpecialization()
        .then((formattedData) => {
          setSelectValueSpecialization(formattedData);
        })
        .catch((error) => {
          console.error('Errore durante il recupero dei dati:', error);
        });

    getProvince(true)
        .then((formattedData) => {
          setSelectValueProvince(formattedData);
        })
        .catch((error) => {
          console.error('Errore durante il recupero dei dati:', error);
        });
    
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleSpecializationChange = async (e) => {
    const query = e.value || (e.target ? e.target.value : "");
    if(!query.type){
      setInputValue(query);
    }
    
    if (query && query.length >= 2) {
      try {
        const practitioners = await getPractitionerListByRequest(query);
        setFilteredPractitioners(practitioners);
      } catch (error) {
        console.error("Errore durante il recupero degli specialisti:", error);
      }
    } else {
      setFilteredPractitioners([]);
    }

    if (query.type === 'practitioner') {
      const DocId = query.id;
      const SpeId = query.specializationId;

      const cleanString = query.name.trim().split(' ').slice(1).join(' ');
      const nome = cleanString.split(' ').slice(0, 1).join(' ');
      const cognome = cleanString.split(',')[0].split(' ').slice(1).join(' ');
      const specifica = cleanString.split(',')[1].trim();
      
      const matchingSpecialization = selectValueSpecialization.find(
          (specialization) => specialization.name === query.specializationDescription
      );

      const SC = matchingSpecialization ? matchingSpecialization.id : null;

      window.location.href = `/profilo-dottore/${encodeURIComponent((cognome || '').replace(/\s+/g, '-'))}-${encodeURIComponent((nome || '').replace(/\s+/g, '-'))}/${encodeURIComponent((specifica || '').replace(/\s+/g, '-'))}/${DocId || ''}/${SpeId || ''}/${SC || ''}`;
    }
    setSelectedValueSpecialization(query);
  };

  const handleProvinceChange = (e) => {
    setSelectedValueProvince(e.value);
  };

  const ShowPractitioner = () => {
    const url = `${config.apiUrl}/SMB/PractitionerToShow?`;

    return fetch(url)
        .then((response) => {
          const contentType = response.headers.get("content-type");
          if (contentType && contentType.includes("application/json")) {
            return response.json();
          } else {
            throw new Error("Response is not JSON");
          }
        })
        .then((responseData) => {
          return responseData.data;
        })
        .catch((error) => {
          console.error('Error fetching data:', error);
          throw error;
        });
  };

  return (
      <div className="main-wrapper home-three">
        <Home3Header/>
        <Helmet>
          <title>3SMB - Prenota il tuo dottore</title>
          <meta name="description" content="Cerchi un dottore? Prenota un appuntamento con centinaia di specialisti. Trova il medico che fa al caso tuo." />
          <meta name="keywords" content="Segreteria medica, dottori, specialisti, prenotazioni, appuntamenti, medici, medico, dottore, specialista" />
        </Helmet>
        {/* Home Banner */}
        <section className="doctor-search-section">
          <style>
            {`
              .input-box-twelve{
                padding: 15px 20px 15px 20px !important;
              }
              
              .our-doctors-section .our-doctors-card .doctors-header .img-overlay,
              .book-btn,
              .book-btn:hover{
                background-color: #1C244B !important;
              }
              
              @media only screen and (min-width: 600px) {
                .doctor-search-section .doctor-search .input-box-twelve .input-block:first-child{
                  width: 50%;
                }
                
              }
              
              @media only screen and (max-width: 600px) {
                .option-h2-mobile{
                  font-size: 26px !important;
                }
                
                .p-dropdown-panel {
                  max-width: 90%;
                }
                
                .nav header-navbar-rht, .header.header-three .header-nav .header-navbar-rht{
                  width: auto !important;
                }
              }
              
              .clinics-section .clinic-item:hover .clinics-card .clinics-img:after{
                background: #0FC2EA !important;
              }
              
              .clinics-section .clinic-item:hover .clinics-card .clinic-img{
                background-color: #0FC2EA !important;
              }
              
               .specialities-section:after{
                background: #1C244B !important;
              }
              
              .doctor-search-section .doctor-search .input-box-twelve .search-btn-info a{
                  background-color: #1C244B !important;
              }
              
              .doctor-search-section .doctor-search .input-box-twelve .search-btn-info a:hover{
                background-color: #0FC2EA !important;
              }
            `}
          </style>
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-7 aos" data-aos="fade-up">
                <div className="doctor-search">
                  <div className="banner-header">
                    <h2>
                      <span>Cerchi un dottore?</span> Prenota un appuntamento
                    </h2>
                    <p>
                      Trova il tuo dottore per nome, provincia o specializzazione.
                    </p>
                  </div>
                  <div className="doctor-form">
                    <form action="#" className="doctor-search">
                      <div className="input-box-twelve mb-3">
                        <div className="search-input input-block">
                          <Dropdown
                              value={inputValue}
                              onChange={handleSpecializationChange}
                              onInput={(e) => handleSpecializationChange(e)}
                              options={combinedOptions}
                              optionLabel="name"
                              placeholder="Medico o specializzazione"
                              className="custom-prime-select"
                              filter
                              filterBy="name"
                          />

                          <Link
                              className="current-loc-icon current_location"
                              to="#"
                          >
                            <i className="fa-solid fa-user-check"/>
                          </Link>
                        </div>
                        <div className="search-input input-block">
                          <Dropdown
                              value={selectedValueProvince}
                              onChange={handleProvinceChange}
                              options={selectValueProvince}
                              optionLabel="name"
                              placeholder="Provincia"
                              className="custom-prime-select"
                              filter
                              filterBy="name"
                          />
                          <Link
                              className="current-loc-icon current_location"
                              to="#"
                          >
                            <i className="fa-solid fa-location-crosshairs"/>
                          </Link>
                        </div>
                        <div className="input-block">
                          <div className="search-btn-info">
                            <Link
                                to={`/lista-dottori/${(selectedValueSpecialization?.name ?? 'und').replace(/\s+/g, '-').replace(/\//g, '-')}/${(selectedValueProvince?.name ?? 'und').replace(/\s+/g, '-')}/${selectedValueProvince?.id ?? 'und'}/${selectedValueSpecialization?.id ?? 'und'}`}>
                              <i className="fa-solid fa-magnifying-glass"/>
                              Cerca
                            </Link>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <div className="col-lg-5 aos" data-aos="fade-up">
                <ImageWithBasePath
                    src="assets/img/banner/doctor-banner.png"
                    className="img-fluid dr-img"
                    alt="doctor-image"
                />
              </div>
            </div>
          </div>
        </section>
        {/* /Home Banner */}
        {/* Clinic Section */}
        <section className="clinics-section">
          <div className="shapes">
            <ImageWithBasePath
                src="assets/img/shapes/shape-1.png"
                alt="shape-image"
                className="img-fluid shape-1"
            />
            <ImageWithBasePath
                src="assets/img/shapes/shape-2.png"
                alt="shape-image"
                className="img-fluid shape-2"
            />
          </div>
          <div className="container">
            <div className="row">
              <div className="col-md-6 aos" data-aos="fade-up">
                <div className="section-heading">
                  <h2 className="option-h2-mobile">Cerca per specializzazione</h2>
                </div>
              </div>
              <div className="col-md-6 text-end aos" data-aos="fade-up">
                <div className="owl-nav slide-nav-1 text-end nav-control"/>
              </div>
            </div>
            <div className="clinics owl-theme aos" data-aos="fade-up">
              <OwlCarousel {...clinicSlider}>
                {clinicData.map((clinic, index) => (
                    <div className="item" key={index}>
                      <div className="clinic-item">
                        <a href={clinic.link}>
                          <div className="clinics-card">
                            <div className="clinics-img">
                              <ImageWithBasePath
                                  src={clinic.image}
                                  alt="clinic-image"
                                  className="img-fluid"
                              />
                            </div>
                            <div className="clinics-info">
                              <span className="clinic-img">
                                <ImageWithBasePath
                                    src={clinic.categoryImage}
                                    alt="category-image"
                                    className="img-fluid"
                                />
                              </span>
                              <span>{clinic.specialization}</span>
                            </div>
                          </div>
                          <div className="clinics-icon">
                            <i className="fas fa-long-arrow-alt-right"/>
                          </div>
                        </a>
                      </div>
                    </div>
                ))}
              </OwlCarousel>
            </div>
          </div>
        </section>
        {/* /Clinic Section */}
        {/* Specialities Section */}
        <section className="specialities-section">
          <div className="shapes">
            <ImageWithBasePath
                src="assets/img/shapes/shape-3.png"
                alt="shape-image"
                className="img-fluid shape-3"
            />
          </div>
          <div className="container">
            <div className="row">
              <div className="col-md-6 aos" data-aos="fade-up">
                <div className="section-heading bg-area">
                  <h2>Cerca per provincia</h2>
                  <p>Trova lo specialista perfetto per te</p>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-xl-3 col-lg-4 col-md-6 aos" data-aos="fade-up">
                <a href={`/lista-dottori/und/Modena/${selectValueProvince.find(province => province.name === 'Modena')?.id}/und`}>
                  <div className="specialist-card d-flex">
                    <div className="specialist-img">
                    </div>
                    <div className="specialist-info">
                      <h4>Modena</h4>
                    </div>
                    <div className="specialist-nav ms-auto">
                      <i className="fas fa-long-arrow-alt-right"/>
                    </div>
                  </div>
                </a>
              </div>
              <div className="col-xl-3 col-lg-4 col-md-6 aos" data-aos="fade-up">
                <a href={`/list-dottori/und/Bologna/${selectValueProvince.find(province => province.name === 'Bologna')?.id}/und`}>
                  <div className="specialist-card d-flex">
                    <div className="specialist-img">
                    </div>
                    <div className="specialist-info">
                      <h4>Bologna</h4>
                    </div>
                    <div className="specialist-nav ms-auto">
                      <i className="fas fa-long-arrow-alt-right"/>
                    </div>
                  </div>
                </a>
              </div>
              <div className="col-xl-3 col-lg-4 col-md-6 aos" data-aos="fade-up">
                <a href={`/lista-dottori/und/Firenze/${selectValueProvince.find(province => province.name === 'Firenze')?.id}/und`}>
                  <div className="specialist-card d-flex">
                    <div className="specialist-img">
                    </div>
                    <div className="specialist-info">
                      <h4>Firenze</h4>
                    </div>
                    <div className="specialist-nav ms-auto">
                      <i className="fas fa-long-arrow-alt-right"/>
                    </div>
                  </div>
                </a>
              </div>
              <div className="col-xl-3 col-lg-4 col-md-6 aos" data-aos="fade-up">
                <a href={`/lista-dottori/und/La-spezia/${selectValueProvince.find(province => province.name === 'La Spezia')?.id}/und`}>
                  <div className="specialist-card d-flex">
                    <div className="specialist-img">
                    </div>
                    <div className="specialist-info">
                      <h4>La Spezia</h4>
                    </div>
                    <div className="specialist-nav ms-auto">
                      <i className="fas fa-long-arrow-alt-right"/>
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </section>
        {/* /Specialities Section */}
        {/* Best Doctor Section */}
        {ShowDoctor.length >= 4 ? (
            <section className="our-doctors-section">
              <div className="container">
                <div className="row">
                  <div className="col-md-6 aos" data-aos="fade-up">
                    <div className="section-heading">
                      <h2>Seleziona per dottore</h2>
                      <p>I nostri dottori selezionati per voi</p>
                    </div>
                  </div>
                  <div className="col-md-6 text-end aos" data-aos="fade-up">
                    <div className="owl-nav slide-nav-2 text-end nav-control"/>
                  </div>
                </div>
                <div className="our-doctors owl-theme aos" data-aos="fade-up">
                  <OwlCarousel {...ourDoctorSlider}>
                    {ShowDoctor.map((doctor, index) => (
                        <div className="item" key={index}>
                          <div className="our-doctors-card">
                            <div className="doctors-header">
                              <a href={`/profilo-dottore/${encodeURIComponent((doctor?.practitioner?.lastName || '').replace(/\s+/g, '-'))}-${encodeURIComponent((doctor?.practitioner?.firstName || '').replace(/\s+/g, '-'))}/${encodeURIComponent((doctor?.specialization?.description || '').replace(/\s+/g, '-'))}/${doctor?.practitioner?.id || ''}/${doctor?.practitionerSpecializationId || ''}/${doctor?.specialization?.id || ''}`}>
                                {doctor.practitioner.practitionerInfo.practitionerImages.length !== 0 && doctor.practitioner.practitionerInfo.practitionerImages[0].url !== null ? (
                                        <img src={doctor.practitioner.practitionerInfo.practitionerImages[0].url}
                                             alt="img-alt"
                                             className="img-fluid"
                                        />
                                    ) :
                                    <img
                                        src="/assets/img/doctor-placeholder.png"
                                        alt="img-alt"
                                        className="img-fluid"
                                    />
                                }
                              </a>
                              <div className="img-overlay">
                                <span>Disponibile</span>
                              </div>
                            </div>
                            <div className="doctors-body">
                              <a href={`/profilo-dottore/${encodeURIComponent((doctor?.practitioner?.lastName || '').replace(/\s+/g, '-'))}-${encodeURIComponent((doctor?.practitioner?.firstName || '').replace(/\s+/g, '-'))}/${encodeURIComponent((doctor?.specialization?.description || '').replace(/\s+/g, '-'))}/${doctor?.practitioner?.id || ''}/${doctor?.practitionerSpecializationId || ''}/${doctor?.specialization?.id || ''}`}>
                                <h4>Dr. {doctor.practitioner.lastName} {doctor.practitioner.firstName}</h4>
                              </a>
                              <div className="location d-flex">
                                <p>
                                  <i className={`fas me-2 fa-map-marker-alt ${!(doctor.practitioner?.addressMunicipality?.municipality || doctor.practitioner?.addressMunicipality?.province) ? 'invisible' : ''}`}/>
                                  {(doctor.practitioner?.addressMunicipality?.municipality || doctor.practitioner?.addressMunicipality?.province) ? (
                                      <>
                                        {doctor.practitioner?.addressMunicipality?.municipality}
                                        {doctor.practitioner?.addressMunicipality?.municipality && doctor.practitioner?.addressMunicipality?.province ? ', ' : ''}
                                        {doctor.practitioner?.addressMunicipality?.province}
                                      </>
                                  ) : null}
                                </p>
                              </div>
                              <div className="row row-sm">
                                <div className="col-12">
                                  <a href={`/profilo-dottore/${encodeURIComponent((doctor?.practitioner?.lastName || '').replace(/\s+/g, '-'))}-${encodeURIComponent((doctor?.practitioner?.firstName || '').replace(/\s+/g, '-'))}/${encodeURIComponent((doctor?.specialization?.description || '').replace(/\s+/g, '-'))}/${doctor?.practitioner?.id || ''}/${doctor?.practitionerSpecializationId || ''}/${doctor?.specialization?.id || ''}`}
                                     className="btn book-btn"
                                     tabIndex={0}>
                                    Prenota dal dottore
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                    ))}
                  </OwlCarousel>
                </div>
              </div>
            </section>
        ) : (<div></div>)}
        <Footer {...props} />
      </div>
  );
};

export default Home;
